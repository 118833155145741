<template>
    <v-container>
        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
            <ImportStores
                :farmId="model.farmId"
                :stores.sync="importedStores"
            ></ImportStores>
        </div>
        <v-spacer></v-spacer>
        <v-row>
            <v-col cols="12" class="d-flex">
                <v-spacer></v-spacer>

                <v-btn color="primary" @click="importStores">
                    Importa
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../../store/actions/store";

export const Promo = () => import("../components/Promo");
export const ImportStores = () => import("../components/ImportStores");
export const Stores = () => import("../components/StoresSelector");

export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    },

    components: {
        ImportStores
    },

    data: () => ({
        ready: false,
        importedStores: []
    }),

    computed: {
        ...mapState({
            loaded: state => state.storeModule.loaded
        })
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES
        }),

        async getStores(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadStores(farmId);
                }
            } catch (err) {
                console.error(err);
            }
        },
        importStores() {
            this.$emit("saveData", this.importedStores);
        }
    },

    mounted() {
        this.$nextTick(async () => {
            this.ready = true;
            await this.getStores(this.model.farmId);
        });
    }
};
</script>

<style></style>
